.forum .background-image-category {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
}
.forum .category-name {
    text-align: center;
    font-size: 11px;
    color: #000;
    font-weight: 600;
    padding-top: 5px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
}

.forum .categories-heading h4 {
    font-family: 'Poppins Semi Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B3467;
}

.forum .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: hidden;
	white-space: nowrap;
    /* background-color: #fff; */
}

.forum .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 5px;
    margin-right: 10px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    border-radius: 8px;
    height: 50px;
    background-color: #fff;
}

.forum .next-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    height: 100%;
    width: 35px;
    background-color: rgba(0,0,0,0.3);
    border-radius: 0 8px 8px 0;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 110%;
    cursor: pointer;
}

.forum .prev-button-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    border-radius: 8px 0 0 8px;
    width: 35px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: 110%;
    cursor: pointer;
}

.forum .next-button-wrapper button,
.forum .prev-button-wrapper button {
    margin: 35px 0;
}

.forum .categories-heading {
    padding: 15px;
}

.forum .professional-wrapper {
    margin: 5px 0;
    padding: 0;
}

.forum .professional-wrapper-selected {
    background: #FFFFFF;
    border: 1.5px solid #D5E0F0;
    border-radius: 20px;
}

.forum .professional-designation-selected {
    padding: 20px 30px 10px;
}

.forum .professional-wrapper .professional-wrapper-row {
    cursor: pointer;
}

.forum .professional-name {
    font-weight: bold;
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
}

.forum .professional-designation {
    font-size: 16px;
    color: #374561;
    font-family: 'Poppins Semi Bold';
}

.forum .professional-designation-not-selected {
    padding: 20px 30px;
    background: #F7F9FF;
    border: 1.5px solid #D5E0F0;
    border-radius: 20px;
}

.forum .professional-designation img {
    float: right;
}

.forum .professional-designation span {
    margin-left: 10px;
    color: #185CD9;
    font-size: 13px;
    font-family: 'Poppins Medium';
}

.forum .professional-location {
    font-size: 12px;
}

.forum .answer {
    padding: 10px 30px 20px;
}

.forum .page-heading {
    display: inline-block;
    border-right: 2px solid #D5DFF0;
    padding-right: 10px;
}

.forum .answer-text {
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px 0;
    font-family: 'Poppins Light';
    width: calc(100% - 100px);
    display: inline-block;
    font-size: 14px;
    background-color: #ECF2FF;
    color: #566986;
}

.forum .answer-text .answer-date {
    color: #1B3467;
    font-size: 13px;
    font-weight: 400;
    float: right;
    font-family: 'Poppins Regular';
}

.forum .question-asked-details {
    padding: 10px;
    width: calc(100% - 100px);
    display: inline-block;
    vertical-align: top;
}

.forum .question-commentedby {
    color: #1B3467;
    font-size: 18px;
    font-family: 'Poppins Medium';
}

.forum .professional-exp {
    font-size: 11px;
    margin-top: 5px;
}

.forum .professional-exp span {
    float: right;
}

.forum .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.forum .background-image-professional,
.forum .background-image-premium {
    width: 60px;
    height: 60px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px 10px 10px 0;
    border-radius: 50%;
    display: inline-block;
    background-color: #FFFFFF;
    box-shadow: 7.6px 17.7333px 39.2667px #dae4f4;
    border: 5px solid #ffffff;
}

.forum .whole-categories-wrapper {
    position: relative;
}

.forum .sort-down {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 25px;
    cursor: pointer;
}

.forum .language-dropdown-wrapper {
    padding: 7px 15px;
}

.forum .answer-text-span-wrapper {
    word-break: break-all;
    padding-top: 5px;
    font-weight: 400;
}

.forum .language-dropdown-wrapper .btn-link {
    text-decoration: underline;
}

.forum .language-dropdown-wrapper .btn-link:hover {
    background-color: transparent;
    color: #5867dd;
}
.forum .language-dropdown-wrapper .dropdown {
    display: inline-block;
    margin-left: 10px;
}

.forum .dropdown-menu {
    right: 0;
}

.forum .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    background-color: #fff;
    border-radius: 8px;
}

.forum .answer-commentedby {
    color: #1B3467;
    font-size: 15px;
    margin-bottom: 5px;
    font-family: 'Poppins Medium';
}

.forum .show-answers-bottom {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
}

.forum .message-input {
    margin-top: 10px;
    width: calc(100% - 100px);
    display: inline-block;
    padding: 7px 0;
    position: relative;
}

.forum .message-input input {
    border-color: #ccc;
}

.forum .message-input .textarea {
    border-radius: 20px;
    border: 1.5px solid #D5E0F0;
    padding: 15px 60px 15px 15px;
}

.forum .message-input button {
    border: none;
    position: absolute;
    right: 5px;
    top: 11px;
}

.forum .message-input button:hover {
    border: none;
    background-color: transparent;
}

.forum .answers-wrapper {
    max-height: 300px;
    overflow: auto;
    /* padding-right: 10px; */
}

.forum .qa-by-you {
    color: #185CD9;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Poppins Medium';
    padding-left: 10px;
    display: inline-block;
}

.forum .status-text {
    font-size: 13px;
    font-family: 'Poppins Medium';
    color: #D97518;
    padding-right: 10px;
}

.forum .confirmed-status {
    color: #2B9770;
}

.forum .qa-date {
    color: #1B3467;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins Regular';
    display: inline-block;
    padding-right: 10px;
    border-right: 2px solid #1B3467;
}

.forum .qa-status {
    color: #185BDB;
    font-size: 12px;
    padding-top: 10px;
}

.arrow-left {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid blue; 
  }


.ask-question-modal .login-logo-wrapper {
    text-align: center;
}

.ask-question-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.ask-question-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.ask-question-modal .login-button {
    padding: 0;
    text-align: center;
}

.ask-question-modal .login-button .btn-primary,
.ask-question-modal .login-button .btn-default {
    margin-bottom: 5px;
    font-size: 18px;
    background: #185BDB;
    border: 1px solid #185BDB;
    border-radius: 50px;
    padding: 10px 20px;
    width: 150px;
    color: #ffffff;
    font-family: 'Poppins Regular';
}
.ask-question-modal .login-button .btn-default {
    background: #ECF2FF;
    color: #1B3467;
    border: 0;
    margin-left: 10px;
}
.ask-question-modal .login-button .btn-link {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
}
.ask-question-modal .login-button .btn-link:hover {
    background-color: transparent;
    color: #185BDB;
}
.ask-question-modal .login-phone-input {
    padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
    color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .question-info {
    margin-bottom: 15px;
    font-size: 14px;
    color: #566986;
    font-family: 'Poppins Medium';
}

.ask-question-modal .question-input label {
    color: #374561;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}

.ask-question-modal .question-textarea {
    background: #EFF5FF;
    border-radius: 15px;
    padding: 15px;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
}

.ask-question-modal .modal-body {
    padding: 10px 25px 25px 25px;
}
.ask-question-modal .info-wrapper {
    border-radius: 50%;
    display: inline-block;
    background-color: #D4DEEC;
    width: 17px;
    height: 17px;
    text-align: center;
    margin-right: 8px;
    vertical-align: middle;
}
.ask-question-modal .info-wrapper img {
    margin-bottom: 4px;
}
.ask-question-modal .question-info i {
    margin-right: 10px;
}

.ask-question-modal .modal-title {
    color: #1B3467 !important;
    font-size: 26px !important;
    font-family: 'Poppins Semi Bold';
    font-weight: 600 !important;
}

.forum .success-payment-wrapper {
    margin: 0px auto;
    text-align: center;
    font-size: 16px;
    max-width: 500px;
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    border-radius: 8px;
}

.forum .success-payment-wrapper .header-1 {
    color: #000;
}

.forum .success-payment-wrapper .header-2 {
    color: #000;
    padding: 10px 0;
}

.forum .success-payment-wrapper .appointment-number {
    color: #185BDB;
    font-size: 18px;
    padding-bottom: 10px;
    font-weight: 800;
    letter-spacing: 10px;
}

.forum .success-payment-wrapper button {
    font-size: 14px;
}

.forum .success-payment-wrapper button.btn-primary {
    margin-left: 10px;
}

.forum.success-payment-outside {
    padding: 15px;
    transform: translateY(50%);
}

.forum .message-input img {
    width: 40px;
}

@media only screen and (min-width: 768px) {
    .forum .forum-more-768-pr-15 {
        padding-right: 15px;
    }
    .forum .forum-more-768-pb-15 {
        padding-bottom: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .forum .forum-less-768-pb-15 {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .forum .professional-near-you-wrapper {
        padding: 10px;
    }
    .forum .forum-less-768-pb-15 {
        padding-bottom: 10px;
    }
    .forum .categories-heading {
        padding: 15px 10px;
    }
    .forum .answer,
    .forum .professional-designation-selected,
    .forum .professional-designation-not-selected {
        padding: 10px;
    }
    .forum .qa-date,
    .forum .qa-by-you,
    .forum .status-text {
        font-size: 12px;
    }
    .forum .professional-designation {
        font-size: 15px;
    }
    .forum .background-image-professional, .forum .background-image-premium {
        width: 50px;
        height: 50px;
    }
    .forum .answer-text {
        padding: 15px;
        width: calc(100% - 70px);
        font-size: 13px;
    }
    .forum .message-input {
        width: calc(100% - 70px);
    }
    .forum .answer-commentedby {
        font-size: 14px;
    }
    .forum .answer-text .answer-date {
        font-size: 12px;
    }
    .forum .message-input .textarea {
        padding: 15px 45px 15px 15px;
    }
    .forum .message-input img {
        width: 30px;
    }
    .forum .message-input button {
        right: 5px;
        top: 18px;
    }
    .forum .categories-heading h4 {
        font-size: 18px;
    }
    .forum .ask-question-modal .modal-body {
        padding: 15px 25px;
    }
    .forum .ask-question-modal .modal-title {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 365px) {
    .forum .success-payment-wrapper button {
        width: 160px;
        margin-bottom: 5px;
    }

    .forum .success-payment-wrapper button.btn-primary {
        margin-left: 0;
    }
}