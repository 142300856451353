// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
    @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@font-face {
  font-family: "Open Sans Regular";
  src: url("./fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  src: url("./fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans Semi Bold";
  src: url("./fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Light";
  src: url("./fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins Semi Bold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.box-shadow {
  box-shadow: 0px 0px 10px -4px rgba(253, 245, 245, 0.75);
  -webkit-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.75);
  background-color: #fff;
}

#myAudio {
  width: 100%;
  margin-top: 10px;
}

#myAudio:focus {
  outline: none;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  // overflow-y: hidden;
}

.header-body-wrapper {
  flex: 1 0 auto;
  // overflow-y: scroll;
  // height: calc(100% - 125px);
}
// .spacer {
//   flex: 1;
// }

.footer {
  height: 60px;
  background-color: #ffffff;
  clear: both;
  min-height: 60px;
  width:100%;
  margin-top: auto;
  flex-shrink: 0;
  opacity: 0.7;
  border-top: 2px solid #EDF1FC;
}

.footer .nav {
  padding: 10px 10px 10px 20px;
  display: inline-block;
}

.footer .nav li {
  display: inline-block;
}

.footer .copyright-text {
  color: #566986;
  font-family: 'Poppins Regular';
  font-size: 12px;
  display: inline-block;
  text-align: right;
  padding: 10px 20px 10px 10px;
}

.footer .nav .nav-link {
  color: #2C3A4E !important;
  font-family: 'Poppins Medium';
}

.btn-primary {
  background-color: #185BDB !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

textarea {
  resize: none;
}

.pl-5 {
  padding-left: 5px !important;
}

h4 {
  font-family: 'Poppins Semi Bold', sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.pt-10 {
  padding-top: 10px;
}
.pt-7 {
  padding-top: 7px;
}

.nav-link, .dropdown-item {
  cursor: pointer;
  color: #185BDB !important;
}

button:disabled {
  cursor: default;
}

.p-15 {
  padding: 15px;
}

.input-error {
  color: #fd397a;
  font-size: 12px;
  margin-top: 5px;
}

.error-border {
  border-color: #fd397a !important;
}

.color-black {
  color: #000;
}

.pointer-none {
  pointer-events: none;
}

.display-block {
  display: block;
}

.show-less-than-768 {
  display: none;
}

.show-more-than-768 {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.btn-outline-primary {
  color: #185CD9;
  border: 2px solid #185CD9;
  font-family: 'Poppins Semi Bold';
  font-size: 14px;
  border-radius: 10px;
}

.btn-outline-default {
  color: #1B3467;
  border: 2px solid #ECF2FF;
  font-family: 'Poppins Semi Bold';
  font-size: 14px;
  border-radius: 10px;
}

.btn-outline-default:hover {
  filter: none;
  background-color: #1B3467;
  color: #fff;
}

.btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link.active {
  background-color: transparent;
  color: #185CD9;
}

.btn.btn-link {
  color: #185CD9;
  text-decoration: underline;
  font-size: 14px;
  font-family: 'Poppins Semi Bold';
}

.modal .modal-content {
  background: #FFFFFF;
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.modal-header {
  padding: 25px 25px 10px 25px;
  border-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .show-less-than-768 {
    display: block;
  }
  .show-more-than-768 {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .pad-0-below-500 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btn.btn-link {
    font-size: 12px;
  }
  .btn-outline-primary,
  .btn-outline-default {
    font-size: 12px;
  }
  .modal-header {
    padding: 15px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .pad-0-below-768 {
    padding: 5px 0 !important;
  }
  .border-top-less-than-768 {
    border-top: 1px solid #ccc;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .border-top-less-than-768 .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .margin-top-less-than-768 {
    margin-top: 10px;
  }
  .navbar-toggler {
    margin-right: 1rem;
  }
  .navbar-brand {
    margin-left: 1rem;
  }
  .footer {
    height: 90px;
  }
  .footer .nav {
    text-align: center;
    padding-bottom: 5px;
  }
  .footer .copyright-text {
    text-align: center;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 440px) {
  .footer .nav .nav-link {
    padding: 5px;
  }
}

@media only screen and (max-width: 395px) {
  .footer .nav li {
    width: 50%;
  }
  .footer {
    height: 105px;
  }
}

.custom-navigation-bar .nav-item.active .nav-link {
  color: #185CD9 !important;
  font-family: 'Poppins Semi Bold';
}

.custom-navigation-bar .nav-item.active {
  border-bottom: 3px solid #F5D45F;
}

.dropdown-item.active {
  color: white !important;
}
