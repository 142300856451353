.problem-area .background-image-category {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 0 auto;
    border-radius: 50%;
}
.problem-area .category-name {
    text-align: center;
    font-size: 13px;
    color: #185CD9;
    font-weight: 600;
    padding-top: 5px;
    white-space: break-spaces;
    width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
    font-family: 'Poppins Regular';
}

.problem-area .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: auto;
	white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    /* background-color: #fff; */
}

.problem-area .categories-wrapper::-webkit-scrollbar {
    display: none;
}

.problem-area .custom-card {
    width: 130px;
    display: inline-block;
    vertical-align: text-top;
    padding: 6px 5px;
    margin-right: 15px;
    box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
    -webkit-box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
    -moz-box-shadow: 6px 12px 40px rgba(125, 139, 165, 0.2);
    border-radius: 15px;
    height: 80px;
    background-color: #fff;
    cursor: pointer;
}

.problem-area .next-button-wrapper {
    height: 45px;
    width: 45px;
    color: #185BDB;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.problem-area .prev-button-wrapper {
    height: 45px;
    background-color: #ffffff;
    color: #185BDB;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    margin-right: 15px;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.problem-area .next-button-wrapper:disabled,
.problem-area .prev-button-wrapper:disabled {
    background-color: #EDF2FF;
    cursor: default;
    opacity: 0.5;
}

.problem-area .next-button-wrapper:hover,
.problem-area .prev-button-wrapper:hover {
    border: 1px solid #E6ECF8;
    filter: none;
}

.problem-area .next-button-wrapper button,
.problem-area .prev-button-wrapper button {
    margin: 35px 0;
}

.problem-area .categories-heading {
    padding: 15px;
}

.problem-area .professional-wrapper {
    padding: 0;
}
.problem-area .problem-category-heading {
    color: #1B3467;
    font-size: 20px;
    font-family: 'Poppins Semi Bold';
}
.problem-area .common-problem-heading {
    color: #185CD9;
    font-size: 20px;
    font-family: 'Poppins Semi Bold';
}
.problem-area .professional-wrapper .row {
    padding: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    border: 1.5px solid #FFF7ED;
    border-radius: 20px;
}

.problem-area .professional-wrapper .faq-wrapper-row {
    padding: 20px 0;
    cursor: pointer;
    margin-bottom: 0;
    border: 0;
    border-bottom: 2px solid #EDF1FC;
    border-radius: 0;
}

.problem-area .professional-wrapper .booking-item-wrapper {
    background-color: #FFF7ED;
    padding: 20px;
    cursor: default;
}

.problem-area .professional-name {
    font-weight: 600;
    color: #374561;
    font-size: 15px;
    margin-bottom: 5px;
    font-family: 'Poppins Medium';
    word-break: break-all;
}

.problem-area .professional-designation {
    font-size: 13px;
    color: #185CD9;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: 'Poppins Regular';
    word-break: break-all;
}

.problem-area .professional-designation img {
    float: right;
}

.problem-area .professional-location {
    font-size: 13px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.problem-area .answer {
    font-size: 14px;
    cursor: default;
    padding: 0 20px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.problem-area .question-text {
    display: inline-block;
    width: calc(100% - 20px);
    vertical-align: top;
}

.problem-area .professional-exp {
    font-size: 11px;
    margin-top: 5px;
}

.problem-area .professional-exp span {
    float: right;
}

.problem-area .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.problem-area .background-image-professional,
.problem-area .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
}

.problem-area .whole-categories-wrapper {
    position: relative;
    padding: 0 15px;
}

.problem-area .sort-down {
    position: absolute;
    top: 20px;
    width: 20px;
    right: 25px;
    cursor: pointer;
}

.problem-area .language-dropdown-wrapper {
    padding: 15px;
}

.problem-area .btn-secondary,
.problem-area .btn.btn-secondary:hover {
    font-size: 14px;
    background-color: #EDF2FF;
    border-radius: 10px;
    padding: 9px 20px;
    color: #566986;
    font-family: 'Poppins Medium';
    filter: none;
}

.problem-area .language-dropdown-wrapper .btn-link {
    text-decoration: underline;
}

.problem-area .language-dropdown-wrapper .btn-link:hover {
    background-color: transparent;
    color: #5867dd;
}
.problem-area .language-dropdown-wrapper .dropdown {
    display: inline-block;
    margin-left: 10px;
}

.problem-area .language-dropdown-wrapper .dropdown-item {
    padding: 7px 10px;
}

.problem-area .language-dropdown-wrapper .item-border {
    border-bottom: 2px solid #ECF0FC;
}

.problem-area .dropdown-menu {
    right: 0;
    left: unset;
    padding: 10px;
}

.problem-area .dropdown-menu span {
    cursor: pointer;
}

.problem-area .professional-near-you-wrapper {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 8px;
}

.problem-area .read-out {
    /* border: 1px solid #ccc; */
    padding: 10px;
    /* border-radius: 8px; */
    color: #374561;
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Poppins Medium';
}

.problem-area .faq-question i {
    float: right;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

.problem-area .faq-question {
    font-size: 15px;
    color: #1B3467;
    font-family: 'Poppins Medium';
}

.problem-area .bor-bot {
    color: #185BDB;
    font-family: 'Poppins Semi Bold';
    padding-bottom: 15px;
}

.faq .category-name {
    padding-top: 8px;
}

.booking-list .professional-name {
    margin-bottom: 0;
    font-size: 16px;
    word-break: break-all;
}

.booking-list .profile-exp {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.booking-list .booking-item-side-row {
    padding: 0 !important;
    cursor: default !important;
    border: 0 !important;
    border-radius: 0 !important;
}

.booking-list .professional-service-desc {
    font-size: 12px;
    color: #566986;
    font-family: 'Poppins Regular';
    margin: 5px 0;
}

.booking-list .professional-amount {
    font-size: 14px;
    color: #185BDB;
    font-family: 'Poppins Medium';
    margin-top: 5px;
}

.booking-list .professional-date-time {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.booking-list .professional-date-time .label {
    color: #1B3467;
    font-size: 14px;
    font-family: 'Poppins Medium';
    margin-bottom: 5px;
}

.booking-list .details-button {
    float: right;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border-radius: 50px;
    border: 1px solid #185BDB;
    padding: 10px 20px;
}

.booking-list .upcoming-past-button {
    border-radius: 50px;
    border: 1px solid #F4F8FF;
    color: #566986;
    padding: 10px 20px;
    background-color: #F4F8FF !important;
    font-family: 'Poppins Regular';
    margin-right: 10px;
}

.booking-list .upcoming-past-button.active {
    border: 1px solid #E6ECF8;
    color: #185BDB !important;
    background-color: #ffffff !important;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border: 1px solid #E6ECF8;
    font-family: 'Poppins Medium';
}

.booking-list .upcoming-past-button:hover {
    filter: none;
    color: inherit;
}

.booking-list .professional-designation {
    color: #566986;
    font-size: 14px;
    margin-top: 5px;
    word-break: break-all;
}

.booking-list .professional-location {
    color: #000;
    font-size: 13px;
    word-break: break-all;
}

.booking-list .professional-exp {
    color: #000;
    font-size: 12px;
}
.booking-list .professional-time {
    color: #185BDB;
    font-size: 12px;
}

.booking-list .booking-profile-image {
    width: 80px;
    height: 80px;
    display: inline-block;
}

.booking-list .booking-profile-image .background-image-professional {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    border: 4px solid #ffffff;
    box-shadow: 6px 14px 31px #dae4f4;
    -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
    -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.booking-list .mobile-view-only {
    display: none;
}

.booking-list .desktop-view-only {
    display: block;
}

.booking-list .booking-details-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 10px;
}

.booking-list .nav-pills .nav-link {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #5d78ff !important;
    padding: 10px 40px !important;
}

.booking-list .nav-pills .active {
    color: #fff !important;
}

.problem-area .ptb-5 {
    padding-top: 10px;
    padding-bottom: 5px;
}

.booking-list .upcoming-past-heading {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    font-size: 16px;
    color: #185BDB;
}

.problem-area .problem-question {
    font-size: 18px;
    color: #374561;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: 'Poppins Semi Bold';
}

.problem-area .problem-category {
    font-size: 14px;
    color: #185CD9;
    margin-bottom: 15px;
    position: relative;
    font-family: 'Poppins Regular';
}

.problem-area-details h4 {
    font-family: 'Poppins Semi Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B3467;
}

.problem-area-details {
    padding: 15px 25px;
}

.problem-area .problem-answer {
    font-size: 16px;
    color: #566986;
    margin-bottom: 15px;
    font-family: 'Poppins Regular';
}

.problem-area .problem-details-wrapper {
    margin: 0 auto;
    line-height: 30px;
}

.problem-area-details .fa-arrow-left {
    margin-right: 15px;
    cursor: pointer;
}

.problem-area .problem-category img {
    position: absolute;
    right: 5px;
    top: 0;
}

.problem-area .selected-card {
    background-color: #185CD9;
}

.problem-area .selected-card-name {
    color: #fff;
}

.booking-list .profile-icon-wrapper i,
.booking-list .profile-icon-wrapper img {
    margin-right: 10px;
    cursor: pointer;
}

.booking-list .profile-icon-wrapper {
    margin: 5px 0;
}

.booking-list .profile-icon-wrapper i {
    color: #185BDB;
    font-size: 14px;
    vertical-align: middle;
}

.booking-list .profile-icon-wrapper img {
    width: 16px;
}

.booking-list .tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
    z-index: 9;
}


.booking-list .tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.booking-list .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.booking-list .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.booking-list .professional-address-title {
    color: #374561;
    font-size: 14px;
    font-family: 'Poppins Medium';
}

.booking-list .pr-20 {
    padding-right: 20px;
}

.booking-list .pl-20 {
    padding-left: 20px;
}

.booking-list .left-booking-item {
    border-right: 2px solid #D4DEEF;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .problem-area .problem-more-768-pr-15 {
        padding-right: 15px;
    }
    .problem-area .problem-more-768-pb-15 {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) and (min-width: 575px) {
    .problem-area .problem-more-768-pr-15-for-2 {
        padding-right: 15px;
    }
    .problem-area .problem-more-768-pb-15-for-2 {
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .problem-area .mt-less-768 {
        margin-top: 10px;
    }
    .booking-list .mobile-view-only {
        display: block;
    }
    .booking-list .desktop-view-only {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .problem-area .professional-near-you-wrapper {
        padding: 10px;
    }
    .problem-area .problem-more-768-pr-15 {
        padding-right: 0;
    }
    .problem-area .problem-more-768-pr-15-for-2 {
        padding-right: 0;
    }
    .problem-area .problem-more-768-pb-15-for-1 {
        padding-bottom: 10px;
    }
    .problem-area .problem-category-heading {
        font-size: 18px;
    }
    .problem-area .common-problem-heading {
        font-size: 18px;
    }
    .problem-area-details h4 {
        font-size: 18px;
    }
    .problem-area .problem-question {
        font-size: 15px;
    }
    .problem-area .problem-answer {
        font-size: 14px;
    }
    .problem-area .problem-category {
        font-size: 13px;
    }
    .problem-area .upcoming-past-button {
        padding: 5px 15px;
    }
}

@media only screen and (max-width: 430px) {
    .booking-list .professional-date-time .label {
        font-size: 12px;
    }
    .booking-list .professional-date-time {
        font-size: 12px;
    }
    .booking-list .professional-amount {
        font-size: 12px;
    }
    .booking-list .professional-amount .amount {
        padding-top: 5px !important;
    }
    .problem-area .professional-wrapper .booking-item-wrapper {
        padding: 10px;
    }
    .problem-area .upcoming-past-button-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 0 !important;
    }
    .booking-list .categories-heading {
        flex: 0 0 100%;
        max-width: 100%;
    }
}