.dashboard .background-image-category {
    width: 60px;
    height: 60px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid #ffffff;
    box-shadow: 6px 14px 31px rgba(24, 91, 219, 0.15);
    -webkit-box-shadow: 6px 14px 31px rgba(24, 91, 219, 0.15);
    -moz-box-shadow: 6px 14px 31px rgba(24, 91, 219, 0.15);
}
.dashboard .category-name {
    text-align: center;
    font-size: 12px;
    color: #566986;
    font-weight: 600;
    padding-top: 7px;
    white-space: break-spaces;
    width: 100px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    display: -webkit-box;
    font-family: 'Poppins Regular';
}

.dashboard .categories-wrapper {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
	overflow-x: auto;
	white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

.dashboard .custom-card {
    width: auto;
    display: inline-block;
    vertical-align: text-top;
    padding: 20px 7px;
    margin-right: 15px;
    height: 130px;
    background-color: #fff;
    cursor: pointer;
}

.dashboard .next-button-wrapper {
    height: 45px;
    width: 45px;
    color: #185BDB;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.dashboard .prev-button-wrapper {
    height: 45px;
    background-color: #ffffff;
    color: #185BDB;
    border-radius: 50%;
    width: 45px;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #E6ECF8;
    font-size: 14px;
}

.dashboard .booking-gallery .prev-button-wrapper,
.dashboard .booking-gallery .next-button-wrapper {
    height: 35px;
    width: 35px;
}

.dashboard .next-button-wrapper:disabled,
.dashboard .prev-button-wrapper:disabled {
    background-color: #EDF2FF;
    cursor: default;
    opacity: 0.5;
}

.dashboard .next-button-wrapper:hover,
.dashboard .prev-button-wrapper:hover {
    border: 1px solid #E6ECF8;
    filter: none;
}

.dashboard .next-button-wrapper button,
.dashboard .prev-button-wrapper button {
    margin: 35px 0;
}

.dashboard .categories-heading {
    padding: 15px;
    position: relative;
}
.dashboard .profile-user-details-side-by-side {
    display: inline-block;
    padding: 0 10px;
}
.dashboard .categories-heading h4 {
    font-family: 'Poppins Semi Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1B3467;
}

.dashboard .categories-heading .category-arrows {
    margin-right: 0;
    margin-left: 0;
}

.dashboard .gallery-wrapper .category-arrows {
    margin-right: 0;
    margin-left: 0;
}

.dashboard .gallery-wrapper h4 {
    color: #1B3467;
    font-size: 18px;
    font-family: 'Poppins Medium';
    font-weight: 600;
}

.dashboard .services-list-wrapper h4 {
    color: #1B3467;
    font-size: 18px;
    font-family: 'Poppins Medium';
    font-weight: 600;
    padding: 20px 0;
    margin-bottom: 0;
}

.dashboard .profile-description-header {
    color: #374561;
    font-size: 15px;
    font-family: 'Poppins Medium';
}

.dashboard .gallery-wrapper {
    padding: 20px 0;
    border-top: 2px solid #D4DEEF;
    border-bottom: 2px solid #D4DEEF;
    margin-top: 20px;
}

.dashboard .profile-description-text {
    color: #566986;
    font-size: 13px;
    font-family: 'Poppins Regular';
}

.dashboard .professional-count {
    margin-left: 10px;
    color: #185CD9;
    font-size: 13px;
    font-family: 'Poppins Medium';
}

.dashboard .categories-heading .home-page-header {
    color: #1B3467;
    font-family: 'Poppins Semi Bold';
    font-size: 20px;
}

.dashboard .professional-wrapper {
    padding: 0;
}

.dashboard .categories-wrapper::-webkit-scrollbar {
    display: none;
}

.dashboard .professional-wrapper .row {
    padding: 15px;
    background: #FFF7ED;
    border-radius: 20px;
    cursor: pointer;
}

.dashboard .professional-name {
    font-weight: 600;
    color: #1B3467;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}

.dashboard .professional-designation {
    font-size: 13px;
    color: #566986;
    font-family: 'Poppins Regular';
}

.dashboard .professional-location {
    font-size: 12px;
    color: #374561;
    font-family: 'Poppins Regular';
}

.dashboard .professional-exp {
    font-size: 12px;
    margin-top: 5px;
    color: #566986;
    font-family: 'Poppins Medium';
}

.dashboard .professional-exp span {
    float: right;
}

.dashboard .professional-exp img {
    margin-right: 3px;
    vertical-align: text-top;
    width: 14px;
}

.dashboard .background-image-professional,
.dashboard .background-image-premium {
    width: 50px;
    height: 50px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    margin: 10px auto;
    border-radius: 50%;
}

.dashboard .whole-categories-wrapper {
    position: relative;
    padding: 0 15px;
}

.dashboard .categories-heading .navbar-nav {
    position: absolute;
    top: 6px;
    right: 0;
    width: 50px;
    cursor: pointer;
}

.dashboard .sort-down {
    /* position: absolute;
    top: 20px;
    width: 20px;
    right: 25px; */
    width: 90%;
}

.dashboard .background-image-premium {
    width: 40px;
    height: 40px;
    margin: 0 auto;
}

.dashboard .premium-listing-wrapper {
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    height: 300px;
    overflow-y: auto;
    background-color: #fff;
    margin-top: 12px;
}

.dashboard .profile-image {
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height: 100px;
    width: 100%;
    border-radius: 50%;
    margin: 0;
    border: 4px solid #ffffff;
    box-shadow: 6px 14px 31px #dae4f4;
    -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
    -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.dashboard .profile-name {
    color: #1B3467;
    font-size: 16px;
    font-family: 'Poppins Medium';
    word-break: break-all;
    font-weight: 600;
}

.dashboard .profile-designation {
    color: #566986;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
    word-break: break-all;
    font-family: 'Poppins Regular';
    font-weight: 500;
}

.dashboard .languages-known {
    font-weight: 400;
}

.dashboard .call-phone-icon-wrapper {
    background-color: #fff;
    padding: 3px 7px;
    border-radius: 50%;
    margin-right: 5px;
}

.dashboard .call-phone-icon-wrapper i {
    transform: rotateY(180deg);
    font-size: 12px;
}

.dashboard .profile-address-title {
    color: #374561;
    font-size: 14px;
    font-family: 'Poppins Medium';
}

.dashboard .profile-exp {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.dashboard .profile-rating-wrapper {
    margin-top: 5px;
}

.dashboard .profile-rating-wrapper img {
    margin: 0 3px;
}

.dashboard .profile-rating-wrapper span {
    color: #185BDB;
    font-weight: 600;
    margin-right: 5px;
    font-size: 14px;
    vertical-align: sub;
}
.dashboard .category-pill {
    border: 1px solid #E6ECF8;
    background-color: #fff !important;
    margin-right: 7px;
    font-size: 11px;
    padding: 5px 15px;
    margin-top: 5px;
}
.dashboard .profile-details-wrapper {
    padding: 20px;
    border-radius: 20px;
    background-color: #FFF7ED;
}

.dashboard .join-call {
    width: 150px !important;
}

.dashboard .user-profile-details-wrapper {
    background-color: #ffffff;
}

.dashboard .book-hours-button {
    border-radius: 50px;
}

.dashboard .service-type {
    color: #566986;
    font-size: 12px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #6B7C95;
}

.dashboard .profile-name-wrapper {
    padding-left: 15px;
    position: relative;
}

.dashboard .big-book-now-button {
    width: 100%;
    margin-top: 10px;
    padding: 4px;
    font-size: 12px;
    background-color: #185BDB;
}

.dashboard .follow-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    padding: 4px 12px;
}

.dashboard .profile-menu-options {
    margin-top: 15px;
    margin-bottom: 15px;
}

.dashboard .about-label {
    font-weight: 600;
}

.dashboard .about-text {
    font-weight: 600;
    color: #185BDB;
}

.dashboard .description-image {
    height: 100px;
    margin: 5px;
    cursor: pointer;
    border-radius: 8px;
}

.dashboard .service-cost {
    color: #185BDB;
    margin-top: 5px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
}

.dashboard .small-book-now-button,
.dashboard .small-book-now-button:hover {
    padding: 4px;
    font-size: 13px;
    background-color: #FFFFFF !important;
    border: 1px solid #E6ECF8;
    box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -webkit-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    -moz-box-shadow: 3px 7px 12px rgba(24, 91, 219, 0.08);
    border-radius: 50px;
    color: #185BDB;
    width: 100px;
    margin-left: 10px;
    filter: none;
}

.dashboard .service-wrapper .row {
    cursor: default;
    border-radius: 0;
    padding: 20px 20px 0;
}

.dashboard .service-name {
    color: #566986;
    font-family: 'Poppins Regular';
    font-weight: 400;
    font-size: 14px;
}

.dashboard .service-list-name {
    color: #374561;
    font-family: 'Poppins Medium';
    font-weight: 600;
    font-size: 16px;
}

.dashboard .professional-near-you-wrapper {
    padding: 15px;
    box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    background-color: #fff;
    border-radius: 8px;
}

.dashboard .booking-details-header {
    color: #185BDB;
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 16px;
    font-family: 'Poppins Medium';
}

.dashboard .cancel-info {
    color: #374561;
    font-weight: 400;
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Poppins Regular';
}


.dashboard .booking-details-name {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
}

.dashboard .booking-details-description {
    font-size: 14px;
    padding-bottom: 5px;
    color: #aaa;
}

.dashboard .booking-details-change-button {
    padding: 10px;
    text-align: center;
}

.dashboard .booking-details-change-button button {
    width: 50%;
    padding: 4px;
}

.dashboard .booking-details-slot {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
    color: #185BDB;
}

.box-shadow-wrapper {
    box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -webkit-box-shadow: 0px 0px 10px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px -4px rgb(0,0,0,0.75);
    padding: 10px;
    border-radius: 8px;
}

.dashboard .fee-label {
    font-weight: 600;
    font-size: 14px;
}

.dashboard .fee-text {
    font-weight: 600;
    color: #374561;
    font-family: 'Poppins Regular';
    display: inline-block;
    font-size: 13px;
    padding: 0 10px;
}

.dashboard .bor-right {
    border-right: 2px solid #6B7C95;;
}

.dashboard .service-details-wrapper {
    padding: 20px 0;
    border-top: 2px solid #D4DEEF;
    border-bottom: 2px solid #D4DEEF;
    margin-top: 20px;
}

.dashboard .profile-amount {
    font-size: 14px;
    color: #185BDB;
    font-family: 'Poppins Medium';
    margin-top: 5px;
}

.dashboard .fee-total-label {
    color: #000;
}

.dashboard .fee-total-wrapper {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

.dashboard .appointment-number-details {
    font-size: 20px;
    letter-spacing: 10px;
    color: #185BDB;
}

.dashboard .slot-wrapper .MuiTextField-root {
    width: 100%;
    margin-top: 9px;
}

.dashboard .slot-wrapper .MuiInput-input {
    background-color: #ffffff;
    color: #566986;
    font-family: 'Poppins Medium';
    font-size: 14px;
    height: 20px;
    padding: 6px 10px;
}

.dashboard .slot-wrapper .form-label {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
    font-weight: 400;
}

.dashboard .slot-wrapper h6 {
    color: #374561;
    font-family: 'Poppins Medium';
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
}

.dashboard .slot-wrapper .MuiInput-underline:before {
    border-bottom: 0;
}

.dashboard .appointment-details-button-wrapper .btn-primary,
.dashboard .appointment-details-button-wrapper .btn-outline-primary,
.dashboard .booking-details-change-button .btn-primary {
    margin-left: 5px;
    font-size: 14px;
    background: #185BDB;
    border: 1px solid #185BDB;
    border-radius: 50px;
    padding: 10px 15px;
    width: 100px;
    color: #ffffff;
    font-family: 'Poppins Regular';
}

.dashboard .pay-now-button .btn-primary {
    max-width: 200px;
    width: 100%;
}

.dashboard .services-list-wrapper .back-button-wrapper button {
    font-size: 13px;
}

.dashboard .slot-wrapper {
    background-color: #FFF7ED;
    padding: 30px 20px;
    border-radius: 20px;
}

.dashboard .appointment-details-button-wrapper .btn-outline-primary,
.dashboard .appointment-details-button-wrapper .btn-outline-primary:hover {
    background: #ffffff;
    border: 1px solid #E6ECF8;
    color: #7A8EB0;
    filter: none;
}

.dashboard .booking-profile-image {
    width: 80px;
    height: 80px;
    display: inline-block;
}

.dashboard .booking-profile-image .background-image-professional {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 0;
    border: 4px solid #ffffff;
    box-shadow: 6px 14px 31px #dae4f4;
    -webkit-box-shadow: 0px 0px 10px -4px #dae4f4;
    -moz-box-shadow: 0px 0px 10px -4px #dae4f4;
}

.dashboard .booking-details-wrapper {
    width: calc(100% - 80px);
    display: inline-block;
    padding-left: 10px;
}

.dashboard .profile-icon-wrapper i,
.dashboard .profile-icon-wrapper img {
    margin-right: 10px;
    cursor: pointer;
}

.dashboard .profile-icon-wrapper {
    margin-top: 5px;
}

.dashboard .profile-icon-wrapper i {
    color: #185BDB;
    font-size: 14px;
    vertical-align: middle;
}

.dashboard .pt-20 {
    padding-top: 20px;
}

.dashboard .pl-20 {
    padding-left: 20px;
}

.dashboard .pb-20 {
    padding-bottom: 20px !important;
}

.dashboard .profile-icon-wrapper img {
    width: 16px;
}


.ask-question-modal .login-logo-wrapper {
    text-align: center;
}

.ask-question-modal .welcome-text {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.ask-question-modal .sign-in-text {
    color: #aaa;
    font-size: 12px;
}

.ask-question-modal .login-button {
    padding: 0;
    text-align: center;
}

.ask-question-modal .login-button button {
    /* width: 100%; */
    margin-bottom: 5px;
    font-size: 14px;
}
.ask-question-modal .login-button .btn-link {
    font-size: 12px;
    color: #185BDB !important;
    text-decoration: underline !important;
    width: auto;
    padding-right: 0;
    padding-left: 0;
}
.ask-question-modal .login-button .btn-link:hover {
    background-color: transparent;
    color: #185BDB;
}
.ask-question-modal .login-phone-input {
    padding: 35px 10px 0;
}

.ask-question-modal .login-phone-input .input-group {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.ask-question-modal .login-phone-input .input-group-text {
    color: #000;
}

.ask-question-modal .accept-checkbox-wrapper {
    font-size: 11px;
    color: #242323;
}

.ask-question-modal .accept-checkbox-wrapper .form-check-label {
    padding-left: 5px;
}

.ask-question-modal .accept-checkbox-wrapper a {
    color: #185BDB !important;
}

.ask-question-modal .modal-body {
    padding: 10px 25px 25px 25px;
}

.ask-question-modal .login-otp-input-wrapper .form-control {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #185BDB;
    font-weight: 600;
}

.ask-question-modal .cancel-otp-page {
    float: left;
}

.ask-question-modal .question-info {
    margin-bottom: 15px;
    font-size: 14px;
    color: #566986;
    font-family: 'Poppins Medium';
}

.ask-question-modal .question-input label {
    color: #374561;
    font-size: 16px;
    font-family: 'Poppins Semi Bold';
}
 
.ask-question-modal .question-info i {
    margin-right: 10px;
}

.ask-question-modal .modal-title {
    color: #1B3467 !important;
    font-size: 26px !important;
    font-family: 'Poppins Semi Bold';
    font-weight: 600 !important;
}

.review-modal .profile-rating-wrapper img {
    width: 25px;
}

.review-modal .review-label {
    color: #000;
    font-size: 14px;
    font-family: 'Open Sans Regular', sans-serif;
}

.review-modal .review-profile-details {
    border-bottom: 1px solid #ccc;
    padding: 5px 0 15px;
    margin: 0 0 15px 0;
}

.review-modal .background-image-premium {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    border-radius: 8px;
    width: 100%;
}

.review-modal .profile-photo {
    width: 60px;
    display: inline-block;
}

.review-modal .profile-other-details {
    width: calc(100% - 110px);
    display: inline-block;
    padding-left: 15px;
}

.my-profile .profile-photo-wrapper {
    width: 120px;
    display: inline-block;
}

.my-profile .profile-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.my-profile .profile-other-details {
    width: calc(100% - 130px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .profile-image-wrapper {
    width: 100px;
    display: inline-block;
}

.dashboard .profile-other-details {
    width: calc(100% - 120px);
    display: inline-block;
    padding-left: 15px;
}

.dashboard .profile-service-desc {
    font-size: 12px;
    color: #566986;
    font-family: 'Poppins Regular';
    margin: 5px 0;
}

.dashboard .profile-date-time {
    color: #566986;
    font-size: 14px;
    font-family: 'Poppins Regular';
}

.dashboard .profile-date-time .label {
    color: #1B3467;
    font-size: 14px;
    font-family: 'Poppins Medium';
    margin-bottom: 5px;
}

.dashboard .pr-20 {
    padding-right: 20px;
}

.dashboard .left-booking-item {
    border-right: 2px solid #D4DEEF;
    margin-bottom: 10px;
}

.dashboard .about-image-wrapper {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
}

.dashboard .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: 9;
}

.dashboard .tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.dashboard .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.dashboard .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.dashboard .selected-card {
    box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -webkit-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    -moz-box-shadow: 5px 5px 15px rgba(125, 139, 165, 0.2);
    border-radius: 15px;
}

.dashboard .selected-card .background-image-category {
    border: 3px solid #ffffff;
}

.dashboard .selected-card-name {
    color: #374561;
    font-family: 'Poppins Medium';
}

.dashboard .service-wrapper .bor-rad-top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.dashboard .service-wrapper .bor-rad-bot {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

@media only screen and (min-width: 768px) {
    .dashboard .dashboard-more-768-pr-15 {
        padding-right: 20px;
    }
    .dashboard .dashboard-more-768-pb-15 {
        padding-bottom: 20px;
    }
    .mid-section {
        padding: 0 15px;
    }
}
@media only screen and (max-width: 767px) {
    .dashboard .dashboard-less-768-pb-15 {
        padding-bottom: 20px;
    }
    .mid-section {
        padding: 15px 0 !important;
    }
    .dashboard .left-booking-item {
        border-right: 0;
    }
    .dashboard .booking-gallery {
        padding-left: 0;
        padding-top: 15px;
        border-top: 2px solid #D4DEEF;
    }
}

@media only screen and (max-width: 575px) {
    .dashboard .professional-near-you-wrapper {
        padding: 10px;
    }
    .dashboard .dashboard-less-768-pb-15 {
        padding-bottom: 10px;
    }
    .dashboard .appointment-details-button-wrapper {
        text-align: center;
    }
    .dashboard .appointment-number-details {
        text-align: center;
    }

    .dashboard .categories-heading .home-page-header {
        font-size: 18px;
    }
    .dashboard .professional-count {
        font-size: 12px;
        display: block;
        margin-left: 0;
        margin-top: 5px;
    }
    .dashboard .categories-heading .category-arrows {
        margin-top: 10px;
    }
    .dashboard .profile-user-details-side-by-side {
        padding: 0;
        width: 100%;
        margin-top: 5px;
    }
    .dashboard .bor-right {
        border: 0;
    }
    .dashboard .fee-text {
        padding: 0 5px !important;
    }
    .dashboard .categories-heading h4 {
        font-size: 18px;
    }
    .dashboard .services-list-wrapper h4 {
        font-size: 15px;
    }
    .dashboard .gallery-wrapper h4 {
        font-size: 15px;
    }
    .dashboard .profile-image-wrapper {
        width: 80px;
    }
    .dashboard .profile-image {
        height: 80px;
    }
    .dashboard .profile-other-details {
        width: calc(100% - 100px);
        display: inline-block;
        padding-left: 15px;
    }

    .dashboard .services-list-wrapper h4 {
        padding-bottom: 10px;
    }

    .dashboard .services-list-wrapper .back-button-wrapper {
        padding-top: 0 !important;
        padding-bottom: 20px;
    }

    .dashboard .service-type {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
        display: block;
        margin-top: 5px;
    }
    
}

@media only screen and (max-width: 340px) {
    .dashboard .next-button-wrapper,
    .dashboard .prev-button-wrapper {
        width: 40px;
        height: 40px;
    }
    .dashboard .booking-details-change-button button {
        margin-top: 10px;
    }
}